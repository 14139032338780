export class FileExtensionToFaIconValueConverter {
    public toView(filename: string) {
        const extension = filename.split('.').pop();
        switch (extension) {
            case 'pdf':
            case 'xml':
            case 'svg':
            case 'eps':
                return `file-${extension}`;
            case 'png':
            case 'jpg':
            case 'gif':
            case 'jpeg':
                return 'file-image';
            case 'mov':
            case 'mp4':
            case 'avi':
                return 'file-video';
            case 'mp3':
                return 'file-music';
            case 'xls':
            case 'xlsx':
                return 'file-excel';
            case 'doc':
            case 'docx':
                return 'file-word';
            case 'ppt':
            case 'pptx':
                return 'file-powerpoint';
            case 'txt':
                return 'file-lines';
            case 'zip':
                return 'file-zipper';
            default:
                return 'file';
        }
    }
}
